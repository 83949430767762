<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :filters="filters" :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="btnJobCreate" title="Tarefa" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
      <div slot="content-between-search-table">
        <div class="text-center">
          <div class="side-by-side div-task">
            <ButtonToggle titleActive="Pendente" titleInactive="Pendente" colorActive="#FF0201"
              classIconActive="fa-regular fa-check-double" classIconInactive="fa-solid fa-triangle-exclamation"
              colorInactive="#F0F0F0" v-model="propsParam.delayed" />
          </div>
          <div class="side-by-side div-task">
            <ButtonToggle titleActive="Hoje" titleInactive="Hoje" colorActive="#FF8A1B"
              classIconActive="fa-regular fa-check-double" classIconInactive="fa-solid fa-calendar-week"
              colorInactive="#F0F0F0" v-model="propsParam.today" />
          </div>
          <div class="side-by-side div-task">
            <ButtonToggle titleActive="Prazo" titleInactive="Prazo" colorActive="#3D4EAE"
              classIconActive="fa-regular fa-check-double" classIconInactive="fa-solid fa-timer" colorInactive="#F0F0F0"
              v-model="propsParam.scheduled" />
          </div>
          <div class="side-by-side div-task">
            <ButtonToggle titleActive="Concluída" titleInactive="Concluída" colorActive="#009183"
              classIconActive="fa-regular fa-check-double" classIconInactive="fa-regular fa-square-check"
              colorInactive="#F0F0F0" v-model="propsParam.done" />
          </div>
        </div>

      </div>
    </ViewTemplateWithTable>
    <Modal title="Tarefa" :width="900" :height="750" v-show="showModal('job')">
      <JobCreateUpdate />
    </Modal>
  </div>
</template>

<script>

import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import ButtonToggle from "@nixweb/nixloc-ui/src/component/forms/ButtonToggle";

import JobCreateUpdate from '../../../components/modules/shared/job/JobCreateUpdate.vue'

import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "JobListView",
  components: {
    ViewTemplateWithTable,
    Modal,
    Button, JobCreateUpdate, RadioGroup, ButtonToggle
  },
  data() {
    return {
      panel: {
        module: "Tarefa",
        title: "",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/shared/job/get-all",
        urlDeleteAllApi: "/api/v1/shared/job/delete",
        showChecks: true,
        headerTable: [
          {
            field: "photo",
            title: "Org...",
            container: "nixloc-photo-user",
            type: "image",
            classCssBody: "img-user",
          },
          {
            field: "subject",
            title: "Assunto",
            type: "link",
            iconSearch: true,
            eventName: "jobUpdate",
            classCssBody: "center-vertical",
          },
          {
            field: "fullTime",
            title: "Período",
            type: "text",
            classCssBody: "center-vertical",
          },
          {
            field: "status",
            title: "Status",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
          {
            field: "guest",
            title: "Participantes",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },

        ],
      },
      filters: [
        {
          type: "date",
          title: "Data Início",
          field: "_DateStart",
          value: "",
        },
        {
          type: "date",
          title: "Data Fim",
          field: "_DateEnd",
          value: "",
        },
      ],
      filter: this.$route.params.filter,
      propsParam: {
        delayed: true,
        today: true,
        scheduled: false,
        done: false,
        any: "",
      },
    };
  },
  created() {
    if (this.filter) {
      if (this.filter == "delayed") this.propsParam.status = "Pendente";
      if (this.filter == "today") this.propsParam.status = "Hoje";
      if (this.filter == "scheduled") this.propsParam.status = "Prazo";
      this.propsParam.any = this.userLogged.user.id;
    }
  },
  mounted() {
    this.propsParam.any = this.userLogged.user.id;
    var filterStorage = this.get_FilterStorage(this.$route.name);
    if (filterStorage) {
      this.propsParam = filterStorage.propsParam;
    }
  },
  computed: {
    ...mapGetters("generic", ["event", "showModal", "get_FilterStorage"]),
    ...mapState("generic", ["selected"]),
    ...mapState("user", ["userLogged"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading", "executedSearch", "_addFilterStorage"]),
    create() {
      this.openModal("job");
      this.addEvent({ name: "jobCreate" });
      this.removeLoading(["btnJobCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "jobUpdate") {
          this.openModal("job");
        }
      },
      deep: true,
    },
    propsParam: {
      handler() {
        this.executedSearch();
        this._addFilterStorage({ routeName: this.$route.name, propsParam: this.propsParam });
      },
      deep: true,
    },
  },
};
</script>
<style>
.job-box {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 8px;
}

.job-delay {
  background-color: red;
}

.job-done {
  background-color: #009183;
}

.job-today {
  background-color: #ff8a1b;
}

.job-scheduled {
  background-color: #3d4eae;
}

.div-guest {
  margin-left: -10px;
}

.img-guest {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.div-tag {
  margin-top: 10px;
  margin-bottom: 20px;
}

.div-task {
  margin-right: 10px;
}
</style>