<template>
  <div>
    <ScrollBar :minHeight="450" :maxHeight="450">
      <table class="table table-responsive-xs">
        <thead>
          <tr>
            <th class="title-header">Número/Data</th>
            <th class="title-header">Nome</th>
            <th class="title-header">Consultor</th>
            <th class="title-header text-center">Status</th>
            <th v-if="hasRule('Rental') && showValue" class="title-header text-center">
              Valor
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody v-for="item in data" :key="item.id">
          <td>
            <div class="div-nowrap div-number">{{ item.numberAndDate }}</div>
          </td>
          <td>
            <div class="div-nowrap div-name" @click="navigateTo(item)">
              <span class="title-name"> {{ item.fullName }}</span>
            </div>
            <div class="div-nowrap div-name">
              <span class="identification">
                {{ item.identification }}
              </span>
            </div>
          </td>
          <td>
            <div class="div-nowrap div-consultant">{{ item.consultant }}</div>
          </td>
          <td class="text-center">
            <div class="side-by-side div-badge">
              <span class="badge badge-info" v-if="item.statusRentalName == 'Orçamento'">Orçamento</span>
              <span class="badge badge-success" v-if="item.statusRentalName == 'Aprovada'">Aprovada</span>
              <span class="badge badge-danger" v-if="item.statusRentalName == 'Reprovada'">Reprovada</span>
            </div>
          </td>
          <td v-if="hasRule('Rental') && showValue" class="text-center">
            <div>{{ item.totalGeneral | currency }}</div>
          </td>
          <td>
            <Button :_key="item.id" type="danger" classIcon="fa-solid fa-trash" size="small" :params="{ id: item.id }"
              :clicked="remove" />
          </td>
        </tbody>
        <tbody v-show="data.length == 0">
          <tr>
            <td colspan="12">
              <span>Nenhum registro adicionado!</span>
            </td>
          </tr>
        </tbody>
      </table>
    </ScrollBar>
  </div>
</template>

<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "ListRentLinked",
  props: ["cardId"],
  components: {
    Button,
    ScrollBar,
  },
  data() {
    return {
      urlGet: "/api/v1/planner/card/get-all-rent-linked",
      urlDelete: "/api/v1/planner/card/delete-rent-linked",
      data: [],
      showValue: false,
    };
  },
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasRule"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideSideBar"]),
    ...mapActions("generic", ["getApi", "postApi"]),
    getAll() {
      let params = {
        url: this.urlGet,
        obj: {
          any: this.cardId,
        },
      };
      this.getApi(params).then((response) => {
        this.data = response.content.data;
      });
    },
    remove(item) {
      let params = {
        url: this.urlDelete,
        obj: {
          id: this.cardId,
          rentId: item.id,
        },
        notNotifyToast: true,
      };
      this.postApi(params).then((reponse) => {
        if (reponse.success) {
          this.removeLoading([item.id]);
          this.getAll();
        }
      });
    },
    navigateTo(item) {
      this.hideSideBar();

      if (this.hasRule("Rental")) {
        this.$router.push({
          name: "rentUpdate",
          params: { id: item.id, type: 1 },
        });
      } else if (this.hasRule("Operational")) {
        this.$router.push({
          name: "rentOperationalList",
        });
      }
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "rentLinkedAdded") {
          this.getAll();
        }
        if (event.name == "cardSelectedEdit") {
          this.showValue = event.data.showValue;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.div-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.div-number {
  max-width: 140px;
  font-size: 13px;
}

.div-name {
  max-width: 250px;
}

.identification {
  font-size: 13px;
}

.div-consultant {
  max-width: 120px;
}

.div-btn {
  margin-top: 15px;
  margin-bottom: 20px;
}

.title-name {
  color: #3f529b;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.title-name:hover {
  text-decoration: underline;
}

.icon-danger {
  font-size: 20px;
  color: #f0134d;
  cursor: pointer;
}

.icon-danger:hover {
  color: #c70f40;
}
</style>
