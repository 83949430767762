<template>
  <div>
    <Report module="Adm" name="Auditoria" urlGet="/api/v1/adm/report/audit" :header="header" :filters="filters" />
  </div>
</template>

<script>

import Report from "@nixweb/nixloc-ui/src/component/report/Report";

export default {
  name: "ReportAudit",
  components: {
    Report
  },
  data() {
    return {
      header: [
        {
          title: "Data de Registro",
          field: "registeredDateStr",
          fieldOrderBy: "RegisteredDate",
          show: true,
          styleHeader: "width:140px;",
          styleBody: "max-width: 130px;",
          initialOrderBy: true,
        },
        {
          title: "Descrição",
          field: "description",
          fieldOrderBy: "Description",
          show: true,

        },
        {
          title: "Ação",
          field: "actionName",
          fieldOrderBy: "ActionName",
          show: true,
          styleHeader: "width:50px;",
          styleBody: "max-width: 50px;",
        },
        {
          title: "Usuário",
          field: "registeredUser",
          fieldOrderBy: "RegisteredUser",
          show: true,
          styleHeader: "width:160px;",
          styleBody: "max-width: 150px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
      ],
      filters: [
        {
          type: "date",
          title: "Data de Registro",
          field: "RegisteredDate",
          value: "",
          required: true,
        },
        {
          type: "options",
          title: "Ação",
          field: "ActionName",
          options: [
            { text: "Adicionar", value: "Adicionar" },
            { text: "Modificar", value: "Modificar" },
            { text: "Remover", value: "Remover" },
            { text: "Login", value: "Login" },
          ],
          value: [],
        },
        {
          type: "multiOptions",
          title: "Usuário",
          field: "RegisteredUser",
          url: "/api/v1/adm/user/select-all-by-user-name",
          propsParams: {},
          value: "",
        },
      ],
    }
  }
}


</script>