<template>
  <div>
    <div class="icon-filter" @click="show = true">
      <div class="side-by-side">
        <div
          class="div-step text-center"
          :style="'background-color:' + card.stepColor"
        ></div>
      </div>
      <div class="side-by-side div-title">
        {{ card.stepName }}
      </div>
    </div>
    <div v-if="show" class="main-select" @mouseleave="show = false">
      <div v-for="item in data">
        <div @click="select(item)">
          <div
            class="div-step side-by-side"
            :style="'background:' + item.color"
          ></div>
          <div class="side-by-side div-title-option">
            <span class="">
              {{ item.content }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "SelectStep",
  props: ["card", "initialValue"],
  data() {
    return {
      data: [],
      currentValueFilter: [],
      show: false,
      urlGet: "/api/v1/planner/step/select-all",
      urlUpdateCard: "/api/v1/planner/card/update-step",
      baseParams: {
        search: "",
        currentPage: 1,
        totalPerPage: 20,
      },
    };
  },
  mounted() {
    this.getAll();
  },
  methods: {
    ...mapActions("generic", ["getApi", "putApi"]),
    getAll() {
      let obj = { ...this.baseParams };
      let params = { url: this.urlGet, obj: obj };
      this.getApi(params).then((response) => {
        this.data = response.content.data;
      });
    },
    select(item) {
      this.show = false;
      let params = {
        url: this.urlUpdateCard,
        obj: {
          id: this.card.id,
          any: item.id,
        },
        notNotifyToast: true,
      };
      this.putApi(params).then(() => {
        this.card.stepName = item.content;
        this.card.stepColor = item.color;
      });
    },
  },
};
</script>

<style scoped>
.main-select {
  position: fixed;
  margin-left: 20px;
  min-width: 200px;
  padding: 15px;
  background-color: white;
  border: 1px solid #e8eaed;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1000 !important;
}

.div-frame {
  border-radius: 20px;
  margin: 5px 0px 0px 5px;
  padding-left: 10px;
  padding-right: 3px;
  font-size: 12px;
}

.title-name {
  margin-top: 12px;
  font-size: 12px;
  color: white;
}

.div-step {
  margin-bottom: -4px;
  border-radius: 20px;
  font-size: 12px;
  width: 20px;
  height: 20px;
}

.div-title {
  margin-left: 10px;
  font-size: 16px;
}

.div-title-option {
  margin-left: 10px;
  font-size: 14px;
}

.icon-filter {
  font-size: 15px;
  cursor: pointer;
}

.title-tag {
  color: black;
}
</style>