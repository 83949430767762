<template>
  <div>
    <Loading type="line" v-if="loading" />
    <div ref="scrollContainer" class="div-main-kanban">
      <draggable tag="div" v-model="columns" class="side-by-side div-column">
        <div v-for="column in columns" class="side-by-side div-kanban">
          <div class="div-title">
            <b-row>
              <b-col sm="1">
                <div @click="editColor(column)">
                  <ColorStep :color="column.color" />
                </div>
              </b-col>
              <b-col sm="9">
                <InputTextEdit
                  :enter="updateStep"
                  :params="column"
                  v-model="column.name"
                />
              </b-col>
              <b-col sm="1" v-if="column.cards.length == 0">
                <div
                  class="text-right div-close fade-in"
                  @click="remove(column)"
                >
                  <i class="fa-sharp fa-solid fa-trash"></i>
                </div>
              </b-col>
            </b-row>
          </div>
          <div v-if="hasRule('Rental') && column.showValue">
            <div v-if="!loadingTotalization">
              <span class="title title-total">
                <i class="fas fa-exchange-alt title"></i> {{ column.totalRent }}
              </span>
              <span class="title title-total">
                - {{ column.totalValue | currency }}
              </span>
            </div>
            <div v-else>
              <vue-loading
                type="bubbles"
                color="orange"
                :size="{ width: '26px', height: '26px' }"
              />
            </div>
          </div>
          <hr class="hr line-card" />
          <div class="div-add-card" @click="addCard(column)">
            <i class="fa-solid fa-circle-plus title"></i>
            <span class="title-column title">Cartão</span>
          </div>
          <ScrollBar
            :minHeight="browserHeightConditional"
            :maxHeight="browserHeightConditional"
          >
            <div class="div-scroll">
              <draggable
                :list="column.cards"
                :animation="200"
                ghost-class="ghost-card"
                group="cards"
                @end="handleDragEnd"
              >
                <Card
                  v-for="card in column.cards"
                  :key="card.id"
                  :card="card"
                  :params="card"
                  :clicked="editCard"
                />
              </draggable>
            </div>
          </ScrollBar>
        </div>
        <div class="side-by-side div-add-kanban" v-if="!loading">
          <div @click="addStep">
            <i class="fa-solid fa-circle-plus title"></i>
            <span class="title-column title">Etapa</span>
          </div>
        </div>
      </draggable>
    </div>
    <SideBar
      :title="typeSideBar == 'edit' ? cardSelected.customerName : 'Cartão'"
    >
      <div v-if="sideBar.open">
        <CardCreate v-if="typeSideBar == 'create'" :step="stepSelected" />
        <CardEdit v-if="typeSideBar == 'edit'" :card="cardSelected" />
      </div>
    </SideBar>
    <Modal title="Cor" :width="255" :height="230" v-show="showModal('color')">
      <ColorStep :id="idStepSelected" :color="colorStepSelected" :edit="true" />
    </Modal>
  </div>
</template>

<script>
import InputTextEdit from "@nixweb/nixloc-ui/src/component/forms/InputTextEdit.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import SideBar from "@nixweb/nixloc-ui/src/component/forms/SideBar";

import draggable from "vuedraggable";
import Card from "./Card.vue";
import CardCreate from "../card/CardCreate.vue";
import CardEdit from "../card/CardEdit.vue";
import ColorStep from "../step/ColorStep.vue";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "Kanban",
  props: ["stringFilter"],
  components: {
    InputTextEdit,
    Modal,
    draggable,
    Card,
    CardCreate,
    Loading,
    CardEdit,
    ScrollBar,
    ColorStep,
    SideBar,
  },
  data() {
    return {
      id: this.$route.params.id,
      urlCreate: "/api/v1/planner/step/create",
      urlUpdate: "/api/v1/planner/step/update",
      urlUpdateCard: "/api/v1/planner/card/update-step",
      urlDelete: "/api/v1/planner/step/delete",
      urlGetAll: "/api/v1/planner/step/get-all",
      urlOrderAll: "/api/v1/planner/step/order-all",
      urlGetAllCard: "/api/v1/planner/card/get-all",
      columnsLoadFirst: [],
      typeSideBar: "",
      stepSelected: {},
      cardSelected: {},
      columns: [],
      loading: false,
      loadingTotalization: true,
      loadingCount: 0,
      isDragging: false,
      sliderPosition: 0,
      browserHeight: 0,
      idStepSelected: "",
      colorStepSelected: "",
    };
  },
  mounted() {
    this.getAll();

    this.browserHeight = window.innerHeight;
    window.addEventListener("resize", this.updateBrowserHeight);
  },
  computed: {
    ...mapGetters("generic", ["event", "showModal"]),
    ...mapState("generic", ["sideBar"]),
    ...mapGetters("user", ["hasRule"]),
    hasChange() {
      if (this.columns.length !== this.columnsLoadFirst.length) {
        return true;
      }

      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].id !== this.columnsLoadFirst[i].id) {
          return true;
        }
      }

      return false;
    },
    browserHeightConditional() {
      return this.browserHeight - 400;
    },
  },
  methods: {
    ...mapMutations("generic", [
      "addEvent",
      "openModal",
      "hideModal",
      "openSideBar",
    ]),
    ...mapActions("generic", [
      "getApi",
      "postApi",
      "putApi",
      "deleteAllApi",
      "orderAllApi",
    ]),
    addCard(column) {
      this.stepSelected = this.findTaskByTitle(column.id);
      this.typeSideBar = "create";
      this.openSideBar();
    },
    editColor(step) {
      this.idStepSelected = step.id;
      this.colorStepSelected = step.color;
      this.openModal("color");
    },
    findTaskByTitle(titleToFind) {
      for (const column of this.columns) {
        if (column.id === titleToFind) {
          return column;
        }
      }
      return null;
    },
    getAll() {
      if (this.loadingCount == 0) this.loading = true;
      let params = {
        url: this.urlGetAll,
        obj: {
          any: this.id,
        },
      };
      this.getApi(params).then((response) => {
        this.columnsLoadFirst = response.content.data;
        this.columns = response.content.data;
        this.loading = false;
        this.loadingCount++;
        this.getAllCard();
        this.totalization();
      });
    },
    getAllCard() {
      let params = {
        url: this.urlGetAllCard,
        obj: {
          any: this.id,
          stringFilter: JSON.stringify(this.stringFilter),
        },
      };
      this.getApi(params).then((response) => {
        let cards = response.content.data;

        this.columns.forEach((column) => {
          column.cards = cards.filter((card) => card.stepId === column.id);
        });
      });

      return [];
    },
    addStep() {
      let params = {
        url: this.urlCreate,
        obj: {
          name: "Novo",
          funnelId: this.id,
        },
        notNotifyToast: true,
      };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.columns.push({
            id: response.content.id,
            name: "Novo",
            color: "#00058B",
            cards: [],
          });
        }
      });
    },
    updateStep(item) {
      let params = {
        url: this.urlUpdate,
        obj: {
          id: item.id,
          name: item.name,
        },
        notNotifyToast: true,
      };
      this.putApi(params).then((response) => {
        if (!response.success) {
          this.getAll();
        }
      });
    },
    remove(item) {
      let params = {
        url: this.urlDelete,
        selected: [item.id],
      };
      this.deleteAllApi(params).then((response) => {
        if (response.success) {
          this.getAll();
        }
      });
    },
    orderAll() {
      let listIds = [];

      this.columns.forEach((item) => {
        listIds.push(item.id);
      });

      let obj = { listIds: listIds };
      let params = { url: this.urlOrderAll, obj: obj };

      this.orderAllApi(params).then((response) => {});
    },
    handleDragEnd(event) {
      var { _underlying_vm_ } = JSON.parse(JSON.stringify(event.item));
      var cardId = _underlying_vm_.id;
      var stepId = "";

      this.columns.forEach((column) => {
        column.cards.forEach((card) => {
          if (card.id === cardId) {
            stepId = column.id;
          }
        });
      });

      let params = {
        url: this.urlUpdateCard,
        obj: {
          id: cardId,
          any: stepId,
        },
        notNotifyToast: true,
      };
      this.putApi(params).then(() => {
        this.totalization();
      });
    },
    editCard(card) {
      this.cardSelected = card;
      this.typeSideBar = "edit";

      let self = this;
      setTimeout(function () {
        self.openSideBar();
        self.addEvent({ name: "cardSelectedEdit", data: card });
      }, 200);
    },
    onHideModal() {
      this.openModal("");
      this.getAll();
    },
    updateBrowserHeight() {
      this.browserHeight = window.innerHeight;
    },
    totalization() {
      this.loadingTotalization = true;
      let self = this;
      setTimeout(function () {
        self.columns.forEach((column) => {
          column.totalRent = 0;
          column.totalValue = 0;
          column.cards.forEach((card) => {
            column.totalValue += card.totalValueRent;
            column.totalRent += card.totalRent;
          });
        });
        self.loadingTotalization = false;
      }, 1000);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (
          event.name == "cardCreated" ||
          event.name == "responsibleUserUpdated" ||
          event.name == "colorStepUpdated" ||
          event.name == "cardRemoved"
        )
          this.getAll();

        if (event.name == "stringFilter") this.getAllCard();
      },
      deep: true,
    },
    columns: {
      handler() {
        if (this.hasChange) this.orderAll();
      },
      deep: true,
    },
    "sideBar.open": {
      handler(value) {
        if (!value) this.getAll();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.div-main-kanban {
  overflow-x: auto;
}

.div-main-kanban::-webkit-scrollbar-track {
  height: 1px;
  background-color: white;
  border-radius: 20px;
}

.div-main-kanban::-webkit-scrollbar {
  height: 7px;
  background-color: #b9babb;
  border-radius: 20px;
}

.div-main-kanban::-webkit-scrollbar-thumb {
  background-color: #b9babb;
  border-radius: 20px;
}

.div-column {
  cursor: move;
  display: flex;
  align-items: flex-start;
}

.div-kanban {
  min-width: 350px;
  margin-right: 30px;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #e8eaed;
  margin-bottom: 50px;
}

.div-add-kanban {
  color: darkblue;
  min-width: 300px;
  margin-right: 30px;
  font-size: 15px;
  cursor: pointer;
  overflow-x: auto;
  white-space: nowrap;
}

.div-add-card {
  color: darkblue;
  margin-bottom: 10px;
  cursor: pointer;
}

.div-scroll {
  margin-right: 5px;
}

.div-close {
  color: red;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.5;
}

.div-close:hover {
  color: red;
  opacity: 10;
}

.div-title {
  margin-bottom: 10px;
  font-size: 15px;
}

.title-column {
  font-size: 15px !important;
  margin-left: 10px;
}

.title-total {
  font-size: 15px;
}

.line-card {
  opacity: 0.4;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>