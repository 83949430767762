<template>
  <div class="margin">
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="4" xl="4">
        <CheckboxGroup field="options" :formName="formName" :initialValue="customerSupplier.options" :options="[
          { text: 'Cliente', value: 'isCustomer' },
          { text: 'Fornecedor', value: 'isSupplier' },
        ]" v-model="customerSupplier.options" />
      </b-col>
    </b-row>
    <Molded>
      <Person :formName="formName" v-model="customerSupplier.personRequest" />
      <b-row>
        <b-col sm="12">
          <RadioGroup title="Status" field="status" :formName="formName" :options="[
            { text: 'Ativo', value: 1 },
            { text: 'Inativo', value: 2 },
          ]" v-model="customerSupplier.status" />
        </b-col>
      </b-row>
    </Molded>
    <br />
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <Select title="Categoria" field="category" :formName="formName" :required="false"
            url="/api/v1/crm/category-customer/select-all" v-model="customerSupplier.categoryCustomer"
            nameNewRegister="categoryCustomerCreateUpdate" titleNewRegister="Categoria Cliente" :widthNewRegister="500"
            :heightNewRegister="250">
            <CategoryCustomerCreateUpdate :registerInSelect="true" v-model="customerSupplier.categoryCustomer" />
          </Select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <Select title="Origem" field="origin" :formName="formName" :required="false"
            url="/api/v1/crm/origin-customer/select-all" v-model="customerSupplier.originCustomer"
            nameNewRegister="originCustomerCreateUpdate" titleNewRegister="Origem" :widthNewRegister="500"
            :heightNewRegister="250">
            <OriginCustomerCreateUpdate :registerInSelect="true" v-model="customerSupplier.originCustomer" />
          </Select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <InputNumber title="Faturamento em Dia(s)" field="dayToBilling" :formName="formName" :maxLength="5"
            type="float" v-model="customerSupplier.dayToBilling" />
        </b-col>
        <!-- <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <InputDecimal title="Lt de Crédito" field="number" :formName="formName" :required="false"
            v-model="customerSupplier.creditLimit" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <InputDecimal title="Lt de Patrimônio" field="number" :formName="formName" :required="false"
            v-model="customerSupplier.patrimonyLimit" />
        </b-col> -->
      </b-row>
    </Molded>
    <br />
    <Molded>
      <Contact :formName="formName" v-model="customerSupplier.contactRequest" />
    </Molded>
    <div v-if="id && !isLoading('panel')">
      <br />
      <b-tabs v-model="tabIndex">
        <b-tab title="Endereço">
          <AddressList v-if="tabIndex == 0" :genericId="id" />
        </b-tab>
        <b-tab title="Contato">
          <ContactList v-if="tabIndex == 1" :customerSupplierId="id" />
        </b-tab>
        <b-tab title="Anexo">
          <br>
          <FileManager :showInModal="true" source="customerSupplier" :genericId="id" />
        </b-tab>
      </b-tabs>
    </div>
    <br />
    <Molded>
      <TextArea title="Observações" field="observation" :required="false" :maxLength="800"
        v-model="customerSupplier.observation" :markFormDirty="!registerInSelect" />
    </Molded>
    <br />
  </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import FileManager from "@nixweb/nixloc-ui/src/component/shared/file-manager/FileManager";

import OriginCustomerCreateUpdate from "../../crm/category-customer/OriginCustomerCreateUpdate.vue";
import CategoryCustomerCreateUpdate from "../../crm/category-customer/CategoryCustomerCreateUpdate.vue";
import ContactList from "../contact/ContactList.vue";
import AddressList from "../address/AddressList.vue";

import Contact from "@nixweb/nixloc-ui/src/component/value-objects/Contact.vue";
import Person from "@nixweb/nixloc-ui/src/component/value-objects/Person.vue";

import CustomerSupplier from "@/components/modules/shared/customer-supplier/CustomerSupplier.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CustomerSupplierCreateUpdate",
  props: {
    _source: {
      type: String,
      default: "customer"
    },
    registerInSelect: Boolean,
    value: Object,
  },
  components: {
    InputText,
    InputDecimal,
    InputNumber,
    Select,
    RadioGroup,
    TextArea,
    CheckboxGroup,
    Person,
    Contact,
    Molded,
    Button,
    CategoryCustomerCreateUpdate,
    AddressList,
    ContactList,
    OriginCustomerCreateUpdate,
    FileManager
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "customerSupplierCreateUpdate",
      customerSupplier: new CustomerSupplier(),
      urlCreate: "/api/v1/crm/customer/create",
      urlUpdate: "/api/v1/crm/customer/update",
      urlGetById: "/api/v1/crm/customer/get-by-id",
      tabIndex: 0,
    };
  },
  created() {
    let options = this._source == "customer" ? "isCustomer" : "isSupplier";
    this.customerSupplier.options = [options];
    this.customerSupplier.source = this._source;

    if (this.id && !this.registerInSelect) this.getById();
    if (this.registerInSelect) this.id = "";
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", ["removeLoading", "hideVodal", "hideSideBar"]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    create() {
      this.customerSupplier.addressValueObject.state = "";
      let params = { url: this.urlCreate, obj: this.customerSupplier };
      this.postApi(params).then((response) => {
        if (response.success && this.registerInSelect) {
          this.$emit("input", {
            id: response.content.id,
            content: `${response.content.person.companyName} (${response.content.person.tradeName})`,
          });
          this.hideVodal(this.formName);
        }
        this.hideSideBar();
        if (response.success && !this.registerInSelect) {
          let route = this._source == "customer" ? "customerUpdate" : "supplierUpdate";
          this.$router.replace({
            name: route,
            params: { id: response.content.id },
          });
        }
        this.removeLoading(["saveSaveCancel", "customerOnRent"]);
      });
    },
    update() {
      this.customerSupplier.addressValueObject.state = "";
      let params = { url: this.urlUpdate, obj: this.customerSupplier };
      this.putApi(params).then(() => {
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.customerSupplier.update(response.content);
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
          self.removeFormDirty();
        }, 100);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel" && !this.registerInSelect) {
          if (!this.id) this.create();
          if (this.id) this.update();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}
</style>
