<template>
  <div>
    <Loading v-show="loading" />
  </div>
</template>

<script>
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import Menu from "@/components/modules/adm/login/Menu.js";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "LoginTemView",
  components: { Loading },
  data() {
    return {
      menu: new Menu(),
      codeLoginTemp: this.$route.params.code,
      companyId: this.$route.params.companyId,
      urlLoginTemp: "api/v1/adm/auth/login-temp",
      loading: true,
    };
  },
  mounted() {
    this.siginTemp();
  },
  computed: {
    ...mapState("user", ["userLogged"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("user", ["addUserLogged", "addItemMenu", "removeItemMenu"]),
    siginTemp() {
      let params = {
        url: this.urlLoginTemp,
        obj: { codeLoginTemp: this.codeLoginTemp, companyId: this.companyId },
      };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.addUserLogged(response.content);
          this.loadMenu();
          this.$router.replace(this.$route.query.redirect || "/dashboard");
        }
      });
    },
    loadMenu() {
      this.removeItemMenu();
      let self = this;
      this.userLogged.user.rules.forEach(function (rule) {
        self.hasRule(rule);
      });
    },
    hasRule(rule) {
      let self = this;
      this.menu.itens.forEach(function (item) {
        if (rule.name === item.rule) self.addItemMenu([item]);
      });
    },
  },
};
</script>
