<template>
    <div>
        <b-row>
            <b-col sm="6">
                <InputText title="Item" field="name" :maxLength="100" :enter="addChecklist" v-model="checklist.name" />
            </b-col>
            <b-col sm="3">
                <div class="div-btn">
                    <Button _key="addChecklist" type="primary" :disabled="checklist.name == ''"
                        classIcon="fa-sharp fa-solid fa-plus" size="small" :clicked="addChecklist" />
                </div>
            </b-col>
        </b-row>
        <i class="fa-regular fa-maximize icon-order"></i>
        <span class="title">
            Clique e arraste para ordenar
        </span>
        <Loading v-if="loading" type="line" />
        <ScrollBar :minHeight="300" :maxHeight="300">
            <table class="table table-responsive-xs">
                <draggable v-model="data" tag="tbody">
                    <tr v-for="item in data">
                        <td class="item-draggable">
                            <div class="side-by-side div-check">
                                <CheckboxSimple v-model="item.done" />
                            </div>
                            <div class="side-by-side">
                                <div class="side-by-side">
                                    <InputTextEdit v-if="!item.done" v-model="item.name" />
                                    <span v-else class="title-done">{{ item.name }}</span>
                                </div>
                                <div v-if="!item.done" class="side-by-side icon-remove" @click="remove(item)">
                                    <i class="fa-sharp fa-solid fa-circle-xmark"></i>
                                </div>
                            </div>
                        </td>
                    </tr>
                </draggable>
                <tbody v-show="data.length == 0">
                    <tr>
                        <td colspan="12">
                            <span>Nenhuma item adicionado!</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ScrollBar>
    </div>
</template>
<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple";
import InputTextEdit from "@nixweb/nixloc-ui/src/component/forms/InputTextEdit";
import draggable from "vuedraggable";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
    name: "ChecklistList",
    props: ["genericId"],
    components: {
        Button,
        InputText,
        ScrollBar,
        Loading,
        draggable,
        CheckboxSimple,
        InputTextEdit
    },
    data() {
        return {
            data: [],
            checklist: {
                name: "",
            },
            urlCreate: "/api/v1/shared/checklist/create",
            urlUpdateAll: "/api/v1/shared/checklist/update-all",
            urlGetAll: "/api/v1/shared/checklist/get-all",
            urlDelete: "/api/v1/shared/checklist/delete",
            loading: true,
        }
    },
    mounted() {
        this.getAll();
    },
    computed: {
        ...mapState("user", ["userLogged"]),
    },
    methods: {
        ...mapActions("generic", ["getApi", "postApi", "putApi", "deleteAllApi"]),
        ...mapMutations("generic", ["removeLoading"]),
        getAll() {
            let params = {
                url: this.urlGetAll,
                obj: {
                    any: this.genericId,
                }
            };
            this.getApi(params).then((response) => {
                this.data = response.content.data;
                this.loading = false;

            });
        },
        addChecklist() {
            if (this.checklist.name) {
                let params = {
                    url: this.urlCreate, obj: {
                        name: this.checklist.name,
                        genericId: this.genericId,
                        userId: this.userLogged.user.id
                    },
                    notNotifyToast: true
                };
                this.postApi(params).then((response) => {
                    if (response.success) {
                        this.checklist.name = "";
                        this.getAll();
                    }
                    this.removeLoading(["addChecklist"]);
                });
            }
        },
        updateAll() {
            let params = {
                url: this.urlUpdateAll, obj: {
                    checklist: this.data,
                },
                notNotifyToast: true
            };
            this.putApi(params).then((response) => { });
        },
        remove(item) {
            let params = {
                url: this.urlDelete,
                selected: [item.id],
            };
            this.deleteAllApi(params).then((response) => {
                if (response.success) {
                    this.getAll();
                }
            });
        },
    },
    watch: {
        data: {
            handler() {
                this.updateAll();
            },
            deep: true,
        },
    },
}

</script>
<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}

.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.item-draggable {
    cursor: move;
}

.without-group {
    font-size: 13px;
    font-style: italic;
    color: grey;
}

.margin {
    margin-bottom: 10px;
}

.title-done {
    text-decoration: line-through;
}

.div-check {
    margin: 5px;
}

.div-btn {
    margin-top: 40px;
}

.icon-remove {
    color: red;
    margin-left: 5px;
    cursor: pointer;
}
</style>