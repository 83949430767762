<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-main">
        <SupplierOrderCreateUpdate :formName="panel.formName" />
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>

import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import SupplierOrderCreateUpdate from "../../../components/modules/supplier/supplier-order/SupplierOrderCreateUpdate.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "SupplierOrderCreateUpdateView",
  components: {
    ViewTemplateWithSalveCancel,
    SupplierOrderCreateUpdate,
  },
  data() {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      panel: {
        module: "Fornecedor",
        title: "Pedido de Fornecedor",
        formName: "rentCreateUpdate",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),

  },
};
</script>
