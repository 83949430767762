<template>
  <div class="margin-page">
    <Loading :center="false" v-show="loading" />
    <div v-show="!loading">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <RadioGroup :formName="formName" :options="[
            { text: 'Pagar', value: 1 },
            { text: 'Receber', value: 2 },
          ]" v-model="payableReceivableCreateUpdate.type" />
        </b-col>
      </b-row>
      <Molded>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="4">
            <InputText title="Descrição" field="description" :formName="formName" :required="true" :maxLength="200"
              v-model="payableReceivableCreateUpdate.description" />
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <InputText title="Documento" field="document" :formName="formName" :maxLength="50"
              v-model="payableReceivableCreateUpdate.document" />
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <InputDecimal title="Valor" field="value" :formName="formName" :required="true" :allowNegative="false"
              v-model="payableReceivableCreateUpdate.value" />
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <DateTime title="Vencimento" field="dueDate" format="DD/MM/YYYY" type="date" placeholder
              :formName="formName" :required="true" v-model="payableReceivableCreateUpdate.dueDate" />
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <DateTime title="Competência" field="competenceDate" format="DD/MM/YYYY" type="date" placeholder
              :formName="formName" :required="true" v-model="payableReceivableCreateUpdate.competenceDate" />
          </b-col>
        </b-row>
      </Molded>
      <div class="repeater">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="6" xl="6" v-if="!id">
            <Toggle title="Repetir lançamento" :color="'#577696'" v-model="isRepeater" />
          </b-col>
        </b-row>
      </div>
      <Molded v-if="isRepeater">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="3" xl="3">
            <SelectStatic title="Intervalo" fieldTarget="timeZone" :data="[
              { content: 'Diariamente', id: 'daily' },
              { content: 'Quinzenal', id: 'quaweekly' },
              { content: 'Mensalmente', id: 'monthly' },
              { content: 'Anualmente', id: 'annually' },
            ]" :markFormDirty="true" v-model="payableReceivableCreateUpdate.repeater" />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="2" xl="2">
            <InputNumber title="Vezes" field="number" :formName="formName" :required="false" :maxLength="2" type="int"
              v-model="payableReceivableCreateUpdate.recurrence" />
          </b-col>
        </b-row>
      </Molded>
      <br v-if="isRepeater" />
      <div
        v-if="payableReceivableCreateUpdate.numberRentPayment > 0 || payableReceivableCreateUpdate.numberRentTeam > 0">
        <Alert type="info">
          <span>
            Conta vinculada a Locação
            <b v-if="payableReceivableCreateUpdate.numberRentTeam > 0">Nº {{
              payableReceivableCreateUpdate.numberRentTeam }}</b>
            <b v-if="payableReceivableCreateUpdate.numberRentPayment > 0"
              v-else-if="payableReceivableCreateUpdate.numberRentTeam <= 0">Nº {{
                payableReceivableCreateUpdate.numberRentPayment }}</b>
            , sua alteração resultará em uma modificação na <b>Locação</b>.
          </span>
        </Alert>
      </div>
      <b-tabs>
        <b-tab title="Dados">
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <Select title="Destinatário (Nome ou CNPJ/CPF)" field="recipient" :formName="formName" :required="true"
                url="api/v1/finance/payable-receivable/select-all-recipient"
                v-model="payableReceivableCreateUpdate.recipient" nameNewRegister="customerSupplierOrCollaboratorCreate"
                titleNewRegister="Destinatário" :widthNewRegister="1200" :heightNewRegister="250">
                <RadioGroup :formName="formName" title="O que será cadastrado?" :options="[
                  { text: 'Cliente ou Fornecedor', value: 1 },
                  { text: 'Colaborador', value: 2 },
                ]" v-model="typeRecipient" />
                <CustomerSupplierCreate v-if="typeRecipient == 1" formName="customerSupplierCreate"
                  :_hideSideBar="false" v-model="payableReceivableCreateUpdate.recipient" />
                <CollaboratorCreate v-else v-model="payableReceivableCreateUpdate.recipient" />
              </Select>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
              <Select title="Categoria" field="category" :formName="formName" :required="false"
                url="api/v1/finance/chart-of-accounts/select-all" :propsParams="{ any: typeCostCenter }"
                v-model="payableReceivableCreateUpdate.chartOfAccounts" :showNewRegister="false">
              </Select>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
              <Select title="Tipo de Pagamento" field="typePayment" :formName="formName" :required="false"
                v-model="payableReceivableCreateUpdate.typePayment" url="/api/v1/finance/type-payment/select-all"
                nameNewRegister="typePaymentCreateUpdate" titleNewRegister="Tipo de Pagamento" :widthNewRegister="500"
                :heightNewRegister="250">
                <TypePaymentCreateUpdate :registerInSelect="true" v-model="payableReceivableCreateUpdate.typePayment" />
              </Select>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <Select title="Conta Bancária" field="bankAccount" :formName="formName" :required="false"
                :disabled="hasBillet" v-model="payableReceivableCreateUpdate.bankAccount"
                url="api/v1/finance/bank-account/select-all" :showNewRegister="false">
              </Select>
              <Alert type="info" v-if="hasBillet">
                <span> Não é permitido alterar a Conta Bancária, pois existe <b>Boleto Bancário</b> vinculado! </span>
              </Alert>
            </b-col>
          </b-row>
          <CheckboxSimple :title="'Marcar como ' + title" v-model="payableReceivableCreateUpdate.paid" />
          <div v-if="payableReceivableCreateUpdate.paid">
            <b-row>
              <b-col xs="12" sm="12" md="6" lg="6" xl="2">
                <DateTime title="Data" field="conciliationDate" format="DD/MM/YYYY" type="date" placeholder
                  :formName="formName" :required="false" v-model="payableReceivableCreateUpdate.conciliationDate" />
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="2">
                <InputDecimal title="Desconto" field="discount" :formName="formName" :required="false"
                  :allowNegative="false" v-model="payableReceivableCreateUpdate.discount" />
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="2">
                <InputDecimal title="Multa" field="fees" :formName="formName" :required="false" :allowNegative="false"
                  v-model="payableReceivableCreateUpdate.fees" />
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Observações">
          <EditorHtml field="observation" :required="false" :maxLength="1500"
            v-model="payableReceivableCreateUpdate.observation" />
        </b-tab>
        <b-tab title="Locação Vinculada"
          v-if="payableReceivableCreateUpdate.id && payableReceivableCreateUpdate.type == 1">
          <RelationshipRentCreateUpdate :payableReceivableId="payableReceivableCreateUpdate.id"
            :payableReceivableValue="payableReceivableCreateUpdate.value" />
        </b-tab>
        <b-tab title="Anexo" v-if="payableReceivableCreateUpdate.id">
          <FileManager :showInModal="false" source="finance" :genericId="payableReceivableCreateUpdate.id" />
        </b-tab>
      </b-tabs>
      <SaveCancel :formName="formName" :marginBottom="15">
        <Button _key="saveAndClose" eventName="saveAndClose" classIcon="fa-solid fa-floppy-disk-circle-arrow-right"
          title="Salvar e Fechar" type="info" :disabled="!isFormValid(formName)" size="medium" />
      </SaveCancel>
    </div>
  </div>
</template>

<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import SelectOption from "@nixweb/nixloc-ui/src/component/shared/SelectOption.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import SaveCancel from "@nixweb/nixloc-ui/src/component/shared/SaveCancel";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import FileManager from "@nixweb/nixloc-ui/src/component/shared/file-manager/FileManager";

import CustomerSupplierCreate from "../../shared/customer-supplier/CustomerSupplierCreate.vue";
import CollaboratorCreate from "../../human-resources/collaborator/CollaboratorCreate.vue";
import TypePaymentCreateUpdate from "../type-payment/TypePaymentCreateUpdate.vue";

import RelationshipRentCreateUpdate from '../relationship-rent/RelationshipRentCreateUpdate.vue'
import PayableReceivableCreateUpdate from "@/components/modules/finance/payable-receivable/PayableReceivable.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "PayableReceivableCreateUpdate",
  props: {
    id: String,
    value: Object,
  },
  components: {
    InputText,
    InputDecimal,
    InputNumber,
    Select,
    SelectStatic,
    RadioGroup,
    SelectOption,
    CheckboxGroup,
    CheckboxSimple,
    Molded,
    Button,
    Alert,
    EditorHtml,
    DateTime,
    Toggle,
    TypePaymentCreateUpdate,
    Loading,
    SaveCancel,
    ScrollBar,
    CustomerSupplierCreate,
    CollaboratorCreate,
    FileManager, RelationshipRentCreateUpdate
  },
  data() {
    return {
      formName: "bankAccountCreateUpdate",
      payableReceivableCreateUpdate: new PayableReceivableCreateUpdate(),
      urlCreate: "/api/v1/finance/payable-receivable/create",
      urlUpdate: "/api/v1/finance/payable-receivable/update",
      urlGetById: "/api/v1/finance/payable-receivable/get-by-id",
      isRepeater: false,
      loading: false,
      typeRecipient: 1,
    };
  },
  created() {
    if (this.id) this.getById();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
    typeCostCenter() {
      if (this.payableReceivableCreateUpdate.type == 1) return "2";
      return "1";
    },
    title() {
      if (this.payableReceivableCreateUpdate.type == 1) return "Pago ";
      return "Recebido ";
    },
    hasBillet() {
      if (this.payableReceivableCreateUpdate.tokenBillet) return true;
      return false;
    }
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", ["removeLoading", "hideSideBar"]),
    ...mapMutations("validation", ["removeFormDirty"]),
    create(eventName) {
      let params = {
        url: this.urlCreate,
        obj: this.payableReceivableCreateUpdate,
      };
      this.postApi(params).then((response) => {
        if (response.success && eventName == "saveAndClose") {
          this.hideSideBar();
        }
        if (response.success) {
          this.payableReceivableCreateUpdate.id = response.content.id;
        }
        this.removeLoading(["saveSaveCancel", "saveAndClose"]);
      });
    },
    update(eventName) {
      let params = {
        url: this.urlUpdate,
        obj: this.payableReceivableCreateUpdate,
      };
      this.putApi(params).then((response) => {
        if (response.success && eventName == "saveAndClose") {
          this.hideSideBar();
        }
        this.removeLoading(["saveSaveCancel", "saveAndClose"]);
      });
    },
    getById() {
      this.loading = true;
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.payableReceivableCreateUpdate.update(response.content);
        let self = this;
        setTimeout(function () {
          self.loading = false;
          self.removeFormDirty();
        }, 500);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel" || event.name == "saveAndClose") {
          if (!this.id) this.create(event.name);
          if (this.id) this.update(event.name);
        }
      },
      deep: true,
    },
    isRepeater: {
      handler(isRepeater) {
        if (!isRepeater) this.payableReceivableCreateUpdate.repeater = { id: "", content: "" };
      },
      deep: true,
    },
    /*  "payableReceivableCreateUpdate.type": {
      handler() {
        this.payableReceivableCreateUpdate.chartOfAccounts = {
          id: "",
          content: "",
        };
      },
      deep: true,
    },*/
  },
};
</script>

<style scoped>
.margin-page {
  margin-right: 10px;
  margin-bottom: 90px;
}

.repeater {
  margin-top: 15px;
  margin-bottom: 15px;
}

.div-balance {
  font-size: 16px;
  margin-top: 3px;
}

.positive {
  color: darkblue;
}

.negative {
  color: red;
}
</style>
