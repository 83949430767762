<template>
  <div class="margin">
    <br />
    <Button v-if="id" _key="btnRentCreate" title="Locação" classIcon="fas fa-plus-circle" type="primary" size="small"
      eventName="rentCreate" :clicked="newRent" />
    <div v-if="id">
      <br />
      <b-row>
        <b-col sm="5">
          <Badge :title="'Nº' + supplierOrder.number.toString()" type="primary" size="medium" />
        </b-col>
      </b-row>
      <br />
    </div>
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="8">
          <InputText title="Identificação" field="name" :formName="formName" :required="false" :maxLength="100"
            v-model="supplierOrder.identification" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="4">
          <Select title="Solicitado por" field="type" :formName="formName" :required="true" :disabled="disabled"
            url="/api/v1/human-resources/collaborator/select-all" :propsParams="{ isConsultant: true }"
            v-model="supplierOrder.collaborator" nameNewRegister="consultantCreateUpdate"
            :showNewRegister="hasRule('HumanResources')" titleNewRegister="Consultor" :widthNewRegister="800"
            :heightNewRegister="250">
            <ConsultantCreateUpdate :registerInSelect="true" v-model="supplierOrder.collaborator" />
          </Select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="8">
          <CustomerWithCreate :formName="formName" v-model="supplierOrder.customer" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="4">
          <Select title="Contato" field="type" :formName="formName" :required="true"
            :disabled="!supplierOrder.customer.id" url="/api/v1/shared/contact/select-all"
            :propsParams="{ any: supplierOrder.customer.id }" v-model="supplierOrder.contact"
            nameNewRegister="contactCreateUpdate" :showNewRegister="hasRule('CRM')" titleNewRegister="Contato"
            :widthNewRegister="750" :heightNewRegister="250">
            <ContactCreateUpdate :registerInSelect="true" :customerSupplierId="supplierOrder.customer.id"
              v-model="supplierOrder.contact" />
          </Select>
        </b-col>
      </b-row>
      <br />
    </Molded>
  </div>
</template>

<script>
import Badge from "@nixweb/nixloc-ui/src/component/layout/Badge.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import Tab from "@nixweb/nixloc-ui/src/component/layout/Tab.vue";
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import FileManager from "@nixweb/nixloc-ui/src/component/shared/file-manager/FileManager";

import CustomerWithCreate from "../../rental/shared/CustomerWithCreate";


import SupplierOrder from "@/components/modules/supplier/supplier-order/SupplierOrder.js";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "SupplierOrderCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: {
    Badge,
    Select,
    TextArea,
    Molded,
    Tab,
    InputText,
    Modal,
    DateTime,
    Button,
    EditorHtml,
    CustomerWithCreate
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "supplierOrderCreateUpdate",
      supplierOrder: new SupplierOrder(),
      urlCreate: "/api/v1/rental/rent/create",
      urlUpdate: "/api/v1/rental/rent/update",
      urlGetById: "/api/v1/rental/rent/get-by-id",
      tabIndex: 0,
      tabs: [
        { index: 1, title: "Produto" },
      ],
    };
  },
  created() {
    if (this.id) {
      // this.getById();
      this.tabIndex = 1;
    }
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "showModal", "isLoading"]),
    ...mapGetters("user", ["hasModule", "hasRule"]),
    statusRental() {
      if (this.rent.statusRental == 1)
        return { title: "Orçamento", type: "info" };

      if (this.rent.statusRental == 2)
        return { title: "Aprovada", type: "success" };

      if (this.rent.statusRental == 3)
        return { title: "Reprovada", type: "danger" };
    },
    tabsByModule() {
      let tabs = [];
      var isSignature = this.hasRule("Signature");
      var isBilling = this.hasRule("Billing");
      var isTaxDocument = this.hasRule("TaxDocument");
      var isOperational = this.hasRule("Operational");
      var isMaintenance = this.hasRule("Maintenance");

      this.tabs.forEach((tab) => {
        if (tab.title == "Assinatura" && isSignature) tabs.push(tab);
        if (tab.title == "Faturamento" && isBilling) tabs.push(tab);
        if (tab.title == "NFS-e" && isTaxDocument) tabs.push(tab);
        if (tab.title == "Manutenção" && isMaintenance) tabs.push(tab);
        if (tab.title == "Movimentação" && isOperational) tabs.push(tab);
        if (
          tab.title != "Faturamento" &&
          tab.title != "Manutenção" &&
          tab.title != "Movimentação" &&
          tab.title != "NFS-e" &&
          tab.title != "Assinatura"
        )
          tabs.push(tab);
      });
      return tabs;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", [
      "addEvent",
      "removeLoading",
      "openModal",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    create() {
      let params = { url: this.urlCreate, obj: this.rent };
      this.postApi(params).then((response) => {
        if (response.success)
          this.$router.replace({
            name: "rentUpdate",
            params: { id: response.content.id, type: response.content.type },
          });
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.rent };
      this.putApi(params).then((response) => {
        if (response.success) {
          let self = this;
          setTimeout(function () {
            self.addEvent({ name: "updateTotalizationRent" });
          }, 100);
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.rent.update(response.content);
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
          self.removeFormDirty();
        }, 100);
      });
    },
    getStatusMovimentStock() {
      let params = {
        url: this.urlItemsMovimentTotalization,
        obj: { rentId: this.id },
      };
      this.getApi(params).then((response) => {
        this.statusMovimentStock = response.content;
      });
    },
    getTotalProductForInvoice() {
      let params = {
        url: this.urlItemsRentTotalizationInvoice,
        obj: { rentId: this.id },
      };
      this.getApi(params).then((response) => {
        this.totalProductForInvoice = response.content;
      });
    },
    newRent() {
      this.$router.push({
        name: "rentSelectOption",
      });
      this.removeLoading(["btnRentCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        let name = event.name;

        if (name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }

        if (name == "periodRentCreateUpdate" || name == "periodRentUpdate") {
          this.openModal("periodRentCreateUpdate");
        }

        if (name == "paymentRentCreate" || name == "paymentRentUpdate") {
          this.openModal("paymentRentCreateUpdate");
        }

        if (name == "generatePeriodRent") this.rent.periodRent = event.data;

        if (
          name == "rentAccepted" ||
          name == "rentProcessed" ||
          name == "rentRejected"
        ) {
          this.getById();
          this.getTotalProductForInvoice();
          this.getStatusMovimentStock();
        }

        if (name == "addedProduct" || name == "removedItemRent") {
          this.getStatusMovimentStock();
          this.getTotalProductForInvoice();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}

.div-period {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.div-date-accepted {
  margin-top: 10px;
}
</style>
