<template>
  <div>
    <Report module="Faturamento" name="Fatura" urlGet="/api/v1/billing/report/invoice" :header="header"
      :filters="filters" />
  </div>
</template>

<script>

import Report from "@nixweb/nixloc-ui/src/component/report/Report";

export default {
  name: "ReportInvoice",
  components: {
    Report
  },
  data() {
    return {
      header: [
        {
          title: "Nº Fatura",
          field: "number",
          fieldOrderBy: "Number",
          show: true,
          styleHeader: "width:80px;",
          styleBody: "max-width: 50px;",
        },
        {
          title: "Nº Loc",
          field: "numberRent",
          fieldOrderBy: "NumberRent",
          show: false,
          styleHeader: "width:80px;",
          styleBody: "max-width: 50px;",
        },
        {
          title: "Dt Emissão",
          field: "dateAcceptedStr",
          fieldOrderBy: "DateAccepted",
          show: true,
          styleHeader: "width:80px;",
          styleBody: "max-width: 50px;",
          initialOrderBy: true,
        },
        {
          title: "Destinatário",
          field: "recipientName",
          fieldOrderBy: "RecipientName",
          show: true,
          styleHeader: "width:550px;",
          styleBody: "max-width: 540px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "CNPJ/CPF",
          field: "document",
          fieldOrderBy: "Document",
          show: true,
          styleHeader: "width:180px;",
          styleBody: "max-width: 170px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Status",
          field: "statusInvoiceName",
          fieldOrderBy: "StatusInvoiceName",
          show: true,
          styleHeader: "width:80px;",
          styleBody: "max-width: 80px;",
        },
        {
          title: "R$ Valor",
          field: "totalStr",
          fieldOrderBy: "Total",
          show: true,
          styleHeader: "width:120px;",
          styleBody: "max-width: 120px;",
        },
      ],
      filters: [
        {
          type: "text",
          title: "Nº Fatura",
          field: "Number",
          value: "",
        },
        {
          type: "text",
          title: "Nº Loc",
          field: "NumberRent",
          value: "",
        },
        {
          type: "date",
          title: "Dt Emissão",
          field: "DateAccepted",
          value: "",
          required: true,
        },
        {
          type: "multiOptions",
          title: "Destinatário",
          field: "RecipientName",
          url: "/api/v1/finance/payable-receivable/select-all-recipient",
          propsParams: {},
          value: "",
        },
        {
          type: "text",
          title: "CNPJ/CPF",
          field: "Document",
          value: "",
        },
        {
          type: "options",
          title: "Status",
          field: "StatusInvoiceName",
          options: [
            { text: "Gerada", value: "Gerada" },
            { text: "Cancelada", value: "Cancelada" },
            { text: "Pendente", value: "Pendente" },
          ],
          value: [],
        },
      ],
    }
  }
}


</script>