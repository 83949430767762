<template>
  <div>
    <SearchByBarcode :urlGet="urlGet" :params="params" :disabled="disabled" />
  </div>
</template>
<script>
import SearchByBarcode from "../../stock/search-product/SearchByBarcode.vue";

import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "SearchByBarcodePdv",
  props: {
    disabled: Boolean,
    params: Object,
  },
  components: {
    SearchByBarcode,
  },
  data() {
    return {
      urlGet: "/api/v1/pdv/add-product-by-barcode",
      urlVerifyAccessory: "/api/v1/pdv/verify-if-has-accessory",
    };
  },
  computed: {
    ...mapGetters("pdv", ["verifyIfExistProduct"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("pdv", ["addProduct"]),
    ...mapMutations("generic", ["addEvent"]),
    ...mapActions("generic", ["postApi"]),
    add(itemRent) {
      if (this.verifyIfExistProduct(itemRent)) {
        this.$toasted.show("Produto já existe", {
          type: "error",
        });
        return;
      }
      this.addProduct(itemRent);
      let self = this;
      setTimeout(function () {
        self.VerifyIfHasAccessory(itemRent.productId);
      }, 300);
      this.addEvent({ name: "calculateTotalization" });
    },
    VerifyIfHasAccessory(productId) {
      let params = {
        url: this.urlVerifyAccessory,
        obj: productId,
        notNotifyToast: true,
      };
      this.postApi(params).then((response) => {
        response.content.data.forEach((accessory) => {
          let product = {
            type: 2,
            typeName: "onlyAccessory",
            productFullName: accessory.fullName,
            productId: accessory.product.id,
            quantity: accessory.quantity,
            totalStock: accessory.totalStock,
            allowWithoutPatrimony: accessory.allowWithoutPatrimony,
            barCode: accessory.barCode,
            totalUnitValue: 0,
            unitDiscount: 0,
            unitInsurance: 0,
            totalUnitDiscount: 0,
            unitCost: 0,
            totalUnitCost: 0,
            totalUnitInsurance: 0,
          };
          this.addProduct(product);
        });
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "executedSearchByBarcode") {
          this.add(event.data);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
</style>