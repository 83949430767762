<template>
    <div>
        <div class="repeater">
            <Toggle title="Repetir lançamento" :color="'#577696'" v-model="isRepeater" />
        </div>
        <Molded v-if="isRepeater">
            <b-row>
                <b-col sm="6">
                    <SelectStatic title="Intervalo" fieldTarget="timeZone" :data="[
                        { content: 'Diariamente', id: 'daily' },
                        { content: 'Quinzenal', id: 'quaweekly' },
                        { content: 'Mensalmente', id: 'monthly' },
                        { content: 'Anualmente', id: 'annually' },
                    ]" :markFormDirty="false" v-model="repeatPayment.repeater" />
                </b-col>
                <b-col sm="2">
                    <InputNumber title="Vezes" field="number" :required="false" :maxLength="2" type="int"
                        v-model="repeatPayment.recurrence" />
                </b-col>
            </b-row>
            <b-row v-if="repeatPayment.recurrence > 1">
                <span>Total de <b> {{ repeatPayment.recurrence }} </b> parcelas no valor de</span>
                <b-col sm="12">
                    <RadioGroup :options="[
                        { text: titleTotal, value: false },
                        { text: titleInstallment, value: true },
                    ]" v-model="repeatPayment.totalValue" />
                </b-col>
            </b-row>
        </Molded>
        <br v-if="isRepeater" />
    </div>
</template>

<script>

import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "RepeatPayment",
    props: ["payment", "value"],
    components: {
        InputNumber, InputDecimal, Molded, Toggle, SelectStatic, RadioGroup
    },
    data() {
        return {
            urlRepeat: "/api/v1/rental/payment-rent/create-repeater",
            isRepeater: false,
            repeatPayment: {
                repeater: "",
                recurrence: 1,
                totalValue: false,
            }
        }
    },
    computed: {
        ...mapGetters("generic", ["event"]),
        titleTotal() {
            return `${this.payment.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} cada`;
        },
        titleInstallment() {
            let value = this.payment.value / this.repeatPayment.recurrence;
            return `${value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} cada`;
        }
    },
    methods: {
        ...mapActions("generic", ["postApi"]),
        createRepearter() {
            let params = {
                url: this.urlRepeat,
                obj: { ...this.payment, ...this.repeatPayment },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                this.$emit("input", response.content);
            });
        }
    },
    watch: {
        repeatPayment: {
            handler(value) {
                this.createRepearter();
            },
            deep: true,
        },
        event: {
            handler(event) {
                if (event.name == "paymentRentCreate") {
                    this.isRepeater = false;
                }
            },
            deep: true,
        },
    },
}
</script>
<style scoped>
.div-radio {
    margin-top: 20px;
}
</style>