<template>
  <div>
    <div v-if="show" class="main-select" @mouseleave="show = false">
      <div v-for="item in data">
        <div @click="select(item)">
          <div
            class="div-frame text-center"
            :style="'background:' + item.color"
          >
            <span class="title-name" :class="{ 'title-tag': item.color == '' }">
              {{ item.name }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="side-by-side icon-filter" @click="show = true">
      <div class="div-add-tag text-center">
        <i class="fa-solid fa-circle-plus"></i>
        Tag
      </div>
    </div>
    <div class="side-by-side" v-for="item in currentValueFilter">
      <div>
        <div class="div-frame text-center" :style="'background:' + item.color">
          <span class="title-name" :class="{ 'title-tag': item.color == '' }">
            {{ item.name }}</span
          >
          <i
            class="fa-sharp fa-solid fa-circle-xmark icon-close"
            :class="{ 'title-tag': item.color == '' }"
            @click="remove(item)"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "Tags",
  props: ["cardId", "initialValue"],
  data() {
    return {
      data: [],
      currentValueFilter: [],
      show: false,
      urlGet: "/api/v1/planner/tag/select-all",
      urlCreateUpdate: "/api/v1/planner/card/create-update-tags",
      baseParams: {
        search: "",
        currentPage: 1,
        totalPerPage: 20,
      },
    };
  },
  mounted() {
    this.getAll();
    this.currentValueFilter = this.initialValue;
  },
  methods: {
    ...mapActions("generic", ["getApi", "putApi"]),
    getAll() {
      let obj = { ...this.baseParams };
      let params = { url: this.urlGet, obj: obj };
      this.getApi(params).then((response) => {
        this.data = response.content.data;
      });
    },
    createUpdateTags() {
      const listIds = this.currentValueFilter.map((x) => x.id);
      let params = {
        url: this.urlCreateUpdate,
        obj: {
          id: this.cardId,
          tags: listIds,
        },
        notNotifyToast: true,
      };
      this.putApi(params).then((response) => {});
    },
    select(item) {
      const exists = this.currentValueFilter.some((x) => x.id === item.id);
      if (!exists) {
        this.currentValueFilter.push(item);
        this.createUpdateTags();
      }

      this.$emit("input", this.currentValueFilter);
      this.show = false;
    },
    remove(item) {
      this.currentValueFilter = this.currentValueFilter.filter(
        (x) => x.id !== item.id
      );
      this.$emit("input", this.currentValueFilter);
      this.createUpdateTags();
    },
  },
};
</script>

<style scoped>
.main-select {
  position: fixed;
  margin-left: 20px;
  min-width: 200px;
  padding: 15px;
  background-color: white;
  border: 1px solid #E8EAED;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1000 !important;
}

.div-frame {
  border-radius: 20px;
  margin: 5px 0px 0px 5px;
  padding-left: 10px;
  padding-right: 3px;
  font-size: 12px;
}

.title-name {
  margin-top: 12px;
  font-size: 12px;
  color: white;
}

.div-add-tag {
  background-color: darkgray;
  width: 55px;
  color: white;
  border-radius: 20px;
  font-size: 12px;
}

.icon-filter {
  font-size: 15px;
  cursor: pointer;
}

.icon-close {
  color: white;
  font-size: 13px;
  margin-left: 1px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

.title-tag {
  color: black;
}
</style>